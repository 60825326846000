<template>
  <v-card class="mx-auto">
    <v-card-text>
      <v-card-title>
        <slot name="title">
          Consumer Price Index
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </slot>
      </v-card-title>
      <v-container>
        <div>
          <ul>
            <li>
              The Consumer Price Index measures the average change in prices
              over time that consumers pay for a basket of goods and services.
            </li>
            <li>CPI is the most widely used measure of inflation.</li>
            <li>
              A CPI reading of 100 means that inflation is back to the level
              that it was in 1982-1984.
            </li>
          </ul>
        </div>
        <div>
          <p>
            For example, readings of 175 and 275 would indicate a rise in the
            inflation level of 75% and 175% respectively.
          </p>
        </div>
        <v-fade-transition v-if="chart">
          <cpi-chart></cpi-chart>
        </v-fade-transition>
        <div class="text-center">
          <v-btn
            class="text-capitalize my-2"
            outlined
            color="primary"
            @click="showCPIDetail()"
            >More about CPI</v-btn
          >
          <v-btn
            class="text-capitalize my-2 ml-2"
            color="info"
            outlined
            @click="chart = !chart"
          >
            {{ chart ? 'Hide' : 'Show' }} CPI History
          </v-btn>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import utils from '@/mixins/utils'
export default {
  mixins: [utils],
  components: {
    CpiChart: () => import('./ChartCPI')
  },
  data: () => ({
    chart: false
  }),
  methods: {
    showCPIDetail() {
      this.newWindow(
        'https://www.investopedia.com/terms/c/consumerpriceindex.asp'
      )
    }
  }
}
</script>

<style></style>
